<!-- 数据中心 -->
<template>
    <div class="workbench">
        <div class="top">
            <img class="head-img" :src="userInfo.headimgurl" />
            <span>{{ userInfo.nickname }}</span>
            <a href="javascript:void(0);" @click="inviteFriends">
                <img src="@/assets/images/yq.png" alt />
                <span>邀请好友</span>
            </a>
        </div>
        <div class="main-info">
            <div class="main-info-hd">
                <p>
                    ￥<span>{{ dataInfo.sum_wallet }}</span>
                </p>
                <p>我在饭十荟平台的总收益</p>
            </div>
            <div class="main-info-bd">
                <div class="item">
                    <span style="display: flex; align-items: center">
                        ￥{{ dataInfo.wallet_balance }}
                        <router-link to="/withdrawal" tag="a">提现</router-link>
                    </span>
                    <span>可提现</span>
                </div>
                <div class="xian"></div>
                <div class="item">
                    <span>￥{{ dataInfo.await_wallet }}</span>
                    <span>待入账</span>
                </div>
                <div class="xian"></div>
                <div class="item">
                    <span>￥{{ dataInfo.withdraw_wallet }}</span>
                    <span>已提现</span>
                </div>
            </div>
        </div>
        <div v-if="isShow">
            <router-link to="/reward" class="up-link" v-if="!(dataInfo.is_show == 0 && userInfo.referrer_lv == 2)">
                <img src="@/assets/images/shengji.png" />
            </router-link>
        </div>
        <router-link to="/MyorderProfit?types=1" class="row">
            <span>我的订单收益</span>
            <span>
                ￥{{ dataInfo.user_wallet }}
                <i>></i>
            </span>
        </router-link>
        <router-link to="/MyorderProfit?types=2" class="row">
            <span>我的团队收益</span>
            <span>
                ￥{{ dataInfo.team_wallet }}
                <i>></i>
            </span>
        </router-link>
        <router-link to="/myteam" class="row">
            <span>我的团队</span>
            <span>
                {{ dataInfo.user_team | userNum }}人
                <i>></i>
            </span>
        </router-link>
        <!-- 
        <router-link to="/sharegood" class="row">
            <span>我的访客</span>
            <span>
                {{ dataInfo.user_visitor }}
                <i>></i>
            </span>
        </router-link>
        -->
    </div>
</template>

<script>
    import {
        getUserWork
    } from "@/http/api";
    export default {
        data() {
            return {
                userInfo: {},
                dataInfo: {},
                isShow: false,
                userToken: ""
            };
        },

        //生命周期 - 挂载完成（可以访问DOM元素）
        mounted() {
            this.userInfo = this.$LStorage.getItem("userInfo");
            this.userToken = this.$LStorage.getItem("userToken").user_token;
            this.getUserWork();
        },

        methods: {
            // 邀请好友
            inviteFriends() {
                if (this.userInfo.is_qrcode == 0) {
                    this.$store.dispatch("changeWechat", true);
                } else if (this.userInfo.is_qrcode == 1) {
                    this.$router.push({
                        path: "/inviteposter"
                    });
                }
            },
            // 获取达人数据
            async getUserWork() {
                const res = await getUserWork({
                    data: {
                        user_token: this.userToken
                    }
                });
                this.dataInfo = res.data;
                this.isShow = true
            }
        },
        filters: {
            userNum(e) {
                if (e) {
                    let team1 = Number(e.team1),
                        team2 = Number(e.team2);
                    return team1 + team2;
                }
            }
        },
    };
</script>
<style lang='scss' scoped>
    //@import url(); 引入公共css类
    .workbench {
        background: #f6f6f6;
        font-size: 0.3rem;

        .top {
            display: flex;
            align-items: center;
            height: 27.6vw;
            position: relative;
            padding: 0 3vw;
            background: #fff;

            .head-img {
                width: 20vw;
                height: 20vw;
                margin-right: 10px;
                border-radius: 50%;
                border: 0.02rem solid #fc6f15;
                object-fit: cover;
            }

            span {
                color: #000;
                font-size: 0.32rem;
            }

            a {
                position: absolute;
                right: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 2rem;
                height: 0.7rem;
                border-top-left-radius: 10vw;
                border-bottom-left-radius: 10vw;
                border: 0.1rem solid #fbd3b9;
                color: #fff;
                font-size: 0.3rem;
                border-right: none;
                background: #fc6f15;

                img {
                    width: 0.3rem;
                    margin-right: 0.1rem;
                }

                span {
                    color: #fff;
                }
            }
        }

        .main-info {
            background: url("../../../../assets/images/drbj.png") no-repeat center;
            background-size: 100%;
            height: 38vw;
        }

        .main-info-hd {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            height: 22vw;

            p {
                color: #fff;
                font-size: 0.23rem;
            }

            span {
                color: #fff;
                font-size: 0.48rem;
                font-weight: bold;
            }
        }

        .main-info-bd {
            display: flex;
            align-items: center;
            justify-content: space-around;
            padding: 0 0.2rem;

            .item {
                display: flex;
                flex-direction: column;
                align-items: center;
                color: #fff;

                a {
                    display: flex;
                    justify-content: center;
                    padding: 2px 5px;
                    background: #e40011;
                    border-radius: 5px;
                    color: #fff;
                    margin-left: 5px;
                }
            }

            .xian {
                background: #ffbc85;
                width: 1px;
                height: 30px;
            }
        }

        .up-link {
            display: block;
            width: 94vw;
            margin: 3vw;

            img {
                width: 100%;
            }
        }

        .row {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 3vw;
            background: #fff;
            height: 60px;
            font-size: 0.28rem;
            border-bottom: 1px solid #ebebeb;
        }
    }
</style>
