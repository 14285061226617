<template>
    <div class="Talent">
        <data-center v-if="tab == 1"></data-center>
        <today-push v-if="tab == 2"></today-push>
        <div class="botBar" v-if="false">
            <ul class="talent-foot">
                <li @click="tab = 1" :class="{'active': tab == 1}">
                    <img
                        :src="require('../../../assets/' + (tab == 1?'yellow2':'tab1') +'.png')"
                        alt
                    />
                    <p>数据中心</p>
                </li>
                <li @click="tab = 2" :class="{'active': tab == 2}">
                    <img
                        :src="require('../../../assets/' + (tab == 2?'yellow1':'tab4') +'.png')"
                        alt
                        class="img1"
                    />
                    <p>今日必推</p>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import DataCenter from "./talentChildren/DataCenter";
//import TodayPush from "./talentChildren/TodayPush";
export default {
    components: {
        DataCenter,
        //TodayPush
    },
    data() {
        return {
            tab: 1
        };
    },
    methods: {}
};
</script>

<style lang="scss">
.Talent {
    width: 100%;
    height: 100vh;
    background: #f6f6f6;
    // padding-bottom: 2rem;
    position: relative;

    .botBar {
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        .talent-foot {
            width: 100%;
            height: 1.14rem;
            display: flex;
            background: #fff;
            margin-top: 0.28rem;

            li {
                width: 50%;
                padding-left: 1.3rem;
                padding-top: 0.17rem;
                box-sizing: border-box;

                img {
                    width: 0.46rem;
                    height: 0.44rem;
                    display: block;
                    margin-bottom: 0.13rem;
                    margin-left: 0.2rem;
                }

                .img1 {
                    width: 0.32rem;
                    height: 0.44rem;
                    display: block;
                    margin-left: 0.35rem;
                }

                p {
                    font-size: 0.23rem;
                    color: #ececec;
                }
            }

            li.active {
                p {
                    color: #fb5300;
                }
            }
        }
    }
}
</style>
